import { createApp } from 'vue'
import App from './App.vue'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'
import 'vant/lib/index.css'

import { Circle } from 'vant';
const app = createApp(App)
app.use(Circle)

app.mount('#app')
