<template>
  <div class="page">
    <div class="popBox">
      <div class="innerBox">
        <van-circle
            class="circleDiv"
            v-model:current-rate="currentRate"
            :rate="rate"
            :speed="50"
            :stroke-width="80"
            size="12.5rem"
            :text="text"
            layer-color="#ebedf0"
            :color="nowColor"
            fill="#fff"
            :clockwise="false"
        />
        <div class="urlText">
          易记域名：{{ Url.toUpperCase() }}
        </div>
        <div class="greenText">
          <p>
            
          </p>
          <p>
            截图保存专员QQ：{{ serverQQ }}
          </p>
        </div>
        <div @click="toPage()" :style="{background: nowColor}" class="jumpButton">
          点击前往官方平台
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, toRefs, computed, watch, onMounted} from 'vue'

export default {
  name: 'App',
  setup () {
    const data = reactive({
// Circle 组件的宽度，默认为 100px
      currentRate: 0,
      circleWidth: 100,
      rate: 100,
      text: computed(() => data.currentRate.toFixed(0) + '%'),
      nowColor: '#FF5755',
      Url: window.server.thisUrl,
      jumpUrl: '',
      urlList: window.server.urlList,
      serverQQ: window.server.serveQQ
    })
    const methods = {
      toPage() {
        // window.location.href = data.jumpUrl
        window.location.replace(data.jumpUrl)
      }
    }
    watch(()=> data.currentRate, (a) => {
      if (a === 100) {
        data.nowColor = '#1AAC19'
        data.text = computed(() => 'GO!')
        setTimeout(()=>{
          methods.toPage()
        },1000)
      }
    }),
    onMounted(()=>{
      var arr = window.server.urlList
      var index = Math.floor((Math.random()*arr.length))
      data.jumpUrl = arr[index]
      document.title = data.jumpUrl
    })

    return {
      ...toRefs(data),
      ...methods
    }
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
body {
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.page {
  height: 120vh;
  width: 120vw;
  background: #e4e8ea;
  overflow: hidden;
  .popBox {
    background: #fff;
    width: 95%;
    max-width: 450px;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 2px 15px 0 #00000040;
    .innerBox {
      position: relative;
      top: -40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .circleDiv {
        .van-circle__text {
          font-size: 48px;
          font-weight: normal;
        }
      }
      .urlText {
        font-size: 22px;
        margin: 20px;
      }
      .greenText {
        color: #1AAC19;
        line-height: 22px;
      }
      .jumpButton {
        color: #fff;
        margin-top: 20px;
        width: 65%;
        padding: 10px;
        border-radius: 10px;
        font-size: 18px;
        margin-bottom: 7%;
      }
    }
  }
}
</style>
